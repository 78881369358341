// LOCAL
// export const API_URL = "http://localhost:9000/api/";
// export const BASE_URL = "http://localhost:9000/";

// export const API_URL = "https://947e-2405-201-6800-d089-2c44-49f2-58f7-1eda.ngrok-free.app/api/";
// export const BASE_URL = "https://947e-2405-201-6800-d089-2c44-49f2-58f7-1eda.ngrok-free.app";

//  QA
// export const API_URL = "https://apiqa.vpickup.com/api/";
// export const BASE_URL = "https://apiqa.vpickup.com/";

//  DEVELOPMENT
export const API_URL = "https://apiqa.vpickup.com/api/";
export const BASE_URL = "https://apiqa.vpickup.com/";



