import {
  Box,
  Grid,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { StarOutlined, Visibility, StarBorder, Add } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import DynamicTable from "../../components/DynamicTable";
import { usePagination } from "../../hooks";
import { $crud } from "../../utils/CrudFactory";
import { useNavigate } from "react-router-dom";
import { orderFilters } from "../../utils/filterConfig";
import PrivacyPolicyModal from "../../components/modals/PrivacyPolicyModal";

export function PrivacyPolicy() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // STATE TO MANAGE FILTERS --------------------------------------
  const [filtrationData, setFiltrationData] = useState({
    orderType: "",
    orderStatus: "",
    position: "",
    search: "",
    from: "",
    to: "",
    amountFrom: "",
    amountTo: "",
  });
  // STATE TO MANAGE FILTERS --------------------------------------

  const [rows, setRows] = useState([]); //STATE TO GET THE ORDER LIST
  const { page, limit, setLimit, setPage, total, setTotal } = usePagination(); // PAGINATION PROPS

  const handleClose = () => {
    setOpen(false);
  };
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================
  async function retrieveData() {
    const { data } = await $crud.retrieve("admin/orders");
    return data;
  }
  // ========================= FUNCTION TO GET THE ORDER DATA FROM DATABASE(calling orders api) =============================

  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    // retrieveData().then((res) => {
    //   setRows(res.result);
    //   setTotal(res.total);
    // });
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [limit, page, filtrationData]);

  // COLUMNS IN THE ORDER TABLE-----------------------------
  const clientColumns = [
    { id: "title", label: "Title", align: "center" },
    { id: "content", label: "Content", align: "center" },
    { id: "content", label: "Created By", align: "center" },
    { id: "content", label: "Created At", align: "center" },
    {
      id: "action",
      label: "View",
      renderCell: (row) => {
        return (
          <IconButton
            sx={{ fontSize: "8px", color: "rgba(104, 60, 140, 1)" }}
            onClick={() => navigate(`/order-detail?id=${row.orderID}`)}
          >
            <Visibility />
          </IconButton>
        );
      },
    },
  ];
  // COLUMNS IN THE ORDER TABLE-----------------------------

  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  const getFilteredData = (data) => {
    setFiltrationData({
      ...filtrationData,
      ...data,
    });
  };
  // FUNCTION TO SET THE FILTERS IN filtrationData STATE -----------------------------
  return (
    <>
      <Grid container sx={{ padding: "0 18px" }}>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: "30px",
              color: "#673C8E", // Return the value directly
            }}
          >
            Privacy policy
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: "right", paddingRight: "24px" }}
        >
          <Button
            onClick={() => setOpen(!open)}
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              ml: 2,
              backgroundColor: "#683C8C",
              color: "#FFFFFF",
              borderColor: "#683C8C",

              "&:hover": {
                backgroundColor: "#683C8C",
                color: "#FFFFFF",
                borderColor: "#683C8C",
              },
            }}
            startIcon={<Add />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ paddingRight: "24px" }}>
        <DynamicTable
          rows={[]}
          type="clients"
          url="client"
          setRows={setRows}
          filterConfig={orderFilters} // Pass the filter checkbox options
          noRecordTitle="No privacy policies yet !" // no data title
          onFilterData={getFilteredData} // filteration data
          onResetFilters={() => {
            setFiltrationData((prev) => ({
              search: prev.search,
              orderType: "",
              orderStatus: "",
              position: "",
              from: "",
              to: "",
              amountTo: "",
              amountFrom: "",
            }));
          }}
          onSearchData={(value) =>
            setFiltrationData({ ...filtrationData, search: value })
          }
          paginationProps={{ limit, page, setLimit, setPage, total }}
          columns={clientColumns} // Pass the columns prop
        />
      </Box>
      {open && <PrivacyPolicyModal open={open} onClose={handleClose} />}
    </>
  );
}
