import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../../assets/images/svg/back.svg";
import defaultBannerImg from "../../assets/images/bannerImg.png";
import upload from "../../assets/images/upload.svg";
import dollar from "../../assets/images/dollar.svg";
import percentIcon from "../../assets/images/percentIcon.svg";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import TextEditor from "../../components/TextEditor";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { $crud } from "../../utils/CrudFactory";
import { BASE_URL } from "../../constants";
import RegularOfferModal from "../../components/modals/RegularOfferModal";

export function CreateRegularOffer() {
  const location = useLocation();
  const [offer, setOffer] = useState(null);
  // Extract id from URL
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openRegularOfferModal, setOpenRegularOfferModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // Drag state

  const initialValues = {
    id: "",
    offerCode: "",
    discountDetails: "",
    description: "",
    termCondition: "",
    discountType: "",
    minOrderValue: "",
    discountValue: "",
    startTime: "",
    startDate: "",
    expireTime: "",
    expireDate: "",
    bannerImg: defaultBannerImg,
    validFor: [],
    archived: false,
  };

  async function retrieveOffer() {
    const { data } = await $crud.retrieve("admin/regular-offer", {
      id,
    });
    return data;
  }
  useEffect(() => {
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
    if (id != null) {
      retrieveOffer().then((res) => {
        setOffer(res.offer);
        setValues({
          ...res.offer,
          validFor: JSON.parse(res.offer.validFor),
          id: res.offer._id,
        });
      });
    }
    // RUNNING THE retrieveData() FUNCTION ---------------------------------
  }, [id]);

  const handleCheckboxChange = (event, setFieldValue, values) => {
    const { value, checked } = event.target;
    const validFor = [...values.validFor];
    if (checked) {
      validFor.push(value);
    } else {
      const index = validFor.indexOf(value);
      if (index > -1) {
        validFor.splice(index, 1);
      }
    }
    setFieldValue("validFor", validFor);
  };

  const onSubmit = async (values) => {
    console.log(values, "------------------");
  };

  const handleOpenRegularOfferModal = (row) => {
    setSelectedRow(row);
    setOpenRegularOfferModal(true);
  };

  // function to close position modal---------------------------------
  const handleCloseModal = () => {
    setOpenRegularOfferModal(false);
    setSelectedRow(null);
  };

  // function to call the api and update the comment -------------------------------------
  const handleOfferArchiveSubmit = async (reason) => {
    // Logic to update position with reason
    const { data, type } = await $crud.put(`admin/regular-offer-archieve`, {
      id: selectedRow._id,
    });
    if (type == "success") {
      navigate("/services/regular-offers");
    }
    handleCloseModal();
  };

  const handleOfferSubmit = async () => {
    const formData = new FormData();
    formData.append("offerCode", values.offerCode);
    formData.append("discountDetails", values.discountDetails);
    formData.append("description", values.description);
    formData.append("termCondition", values.termCondition);
    formData.append("discountType", values.discountType);
    formData.append("minOrderValue", values.minOrderValue);
    formData.append("discountValue", values.discountValue);
    formData.append("startDate", values.startDate);
    formData.append("startTime", values.startTime);
    formData.append("expireDate", values.expireDate);
    formData.append("expireTime", values.expireTime);
    formData.append("validFor", JSON.stringify(values.validFor)); // If validFor is an array
    formData.append("archived", values.archived);

    if (offer != []) {
      formData.append("id", values.id);
    }

    if (selectedFile) {
      formData.append("bannerImg", values.bannerImg);
    }

    try {
      const { data } = id
        ? await $crud.put(`admin/regular-offer`, formData)
        : await $crud.post(`admin/regular-offer`, formData);

      if (data) {
        // Navigate after success
        navigate("/services/regular-offers");
      }
    } catch (err) {
      // No need for error handling here, as it’s already handled by $crud.send
      console.log("Offer submission failed: ", err.message);
    }
  };

  // Function to handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false); // Reset drag state
    const file = event.dataTransfer.files[0]; // Get the dropped file
    if (file) {
      handleFileChange({ target: { files: [file] } }); // Use handleFileChange logic
    }
  };
  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file); // Create a URL for the file
      setSelectedFile(fileUrl);
      setFieldValue("bannerImg", file); // Update Formik field with the file object
    }
  };

  // Function to open file manager
  const handleBoxClick = () => {
    fileInputRef.current.click();
  };
  
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format.").required("Required!"),
    password: Yup.string().required("Required!"),
  });
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const handleBackClick = () => {
    navigate(-1);
  };
  // console.log(values);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container sx={{ padding: "0 18px", position: "relative" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: 1,
            }}
          >
            <IconButton onClick={handleBackClick} sx={{ marginLeft: "-10px" }}>
              <img src={back} />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "30px",
                color: "#673C8E", // Return the value directly
              }}
            >
              Regular Offers
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ width: "100%", marginTop: "42px" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                color: "#673C8E",
                marginBottom: "13px",
              }}
            >
              Description<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "38px 43px",
                border: "1px solid #673C8E",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "2px",
                }}
              >
                Offer Code
              </Typography>
              <TextField
                name="offerCode"
                placeholder="VPICKUP12B"
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.offerCode}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                error={touched.offerCode && !!errors.offerCode}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "2px",
                }}
              >
                Discount Details
              </Typography>
              <TextField
                name="discountDetails"
                placeholder="50% OFF"
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.discountDetails}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "2px",
                }}
              >
                Description (20 to 150 characters)
              </Typography>
              <TextField
                name="description"
                multiline={true}
                placeholder="Use code VPickUpIT & get 50% off on orders above $149.Maximum discount:$100."
                sx={{
                  color: "#1C1B1F",
                  mb: 1,
                  "& .MuiInputLabel-root": {
                    color: "#1C1B1F !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: (theme) => theme.palette.text.secondary,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1C1B1F",
                    },
                  },
                }}
                size="small"
                fullWidth
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
              />
              <TextEditor
                type="regularOffer"
                value={values.termCondition}
                setFieldValue={setFieldValue}
              />
            </Box>

            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                color: "#673C8E",
                marginBottom: "13px",
                marginTop: "30px",
              }}
            >
              Discount Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "24px 43px",
                border: "1px solid #673C8E",
                borderRadius: "4px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  marginBottom: "2px",
                }}
              >
                Discount Type
              </Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.discountType}
                  name="discountType"
                  onChange={(e) =>
                    setFieldValue("discountType", e.target.value)
                  }
                  sx={{
                    mt: 1,
                    color: "#1C1B1F",
                    mb: 1,
                    "& .MuiSelect-select": {
                      borderColor: "#673C8E",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#673C8E",
                    },
                  }}
                  // onChange={handleChange}
                >
                  <MenuItem value="percentOff">Percent Off</MenuItem>
                  <MenuItem value="fixedValue">Fixed Value</MenuItem>
                </Select>
              </FormControl>
              {values.discountType == "fixedValue" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      marginBottom: "4px",
                      mt: 1,
                    }}
                  >
                    Fixed Amount Value
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: "#673C8E",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={dollar} />
                    </Box>
                    <TextField
                      name="minOrderValue"
                      placeholder="400"
                      type="number"
                      sx={{
                        color: "#1C1B1F",
                        mb: 1,
                        "& fieldset": {
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        },
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                          borderTopLeftRadius: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={values.minOrderValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                  </Box>
                </>
              )}
              {values.discountType == "percentOff" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      marginBottom: "4px",
                      mt: 1,
                    }}
                  >
                    Discount Percentage
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      name="discountValue"
                      placeholder="10"
                      type="number"
                      sx={{
                        color: "#1C1B1F",
                        mb: 1,
                        "& fieldset": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                        "& .MuiInputLabel-root": {
                          color: "#1C1B1F !important",
                          borderTopLeftRadius: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: (theme) =>
                              theme.palette.text.secondary,
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1C1B1F",
                          },
                        },
                      }}
                      size="small"
                      fullWidth
                      value={values.discountValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    />
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        background: "#673C8E",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={percentIcon} />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "22px",
                color: "#673C8E",
                marginBottom: "13px",
                marginTop: "30px",
              }}
            >
              Offer Validity Period<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "24px 43px",
                border: "1px solid #673C8E",
                borderRadius: "4px",
                marginBottom: "48px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "6px",
                }}
              >
                Starting On
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 3,
                  mb: 2,
                }}
              >
                <LocalizationProvider
                  sx={{
                    "& .PrivatePickersPopperRoot-popupIndicator": {
                      display: "none",
                    },
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    value={values?.startDate ? dayjs(values.startDate) : null}
                    onChange={(newValue) => {
                      const formattedDate = dayjs(newValue).toISOString();
                      setFieldValue("startDate", formattedDate);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={
                      values?.startTime
                        ? dayjs(values.startTime, "HH:mm")
                        : null
                    }
                    onChange={(newValue) => {
                      const formattedTime = dayjs(newValue?.$d).format("HH:mm");
                      setFieldValue("startTime", formattedTime);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>

              <Typography
                sx={{
                  fontSize: "18px",
                  marginBottom: "6px",
                }}
              >
                Expires On
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <LocalizationProvider
                  sx={{
                    "& .PrivatePickersPopperRoot-popupIndicator": {
                      display: "none",
                    },
                  }}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    value={values?.expireDate ? dayjs(values.expireDate) : null}
                    onChange={(newValue) => {
                      const formattedDate = dayjs(newValue).toISOString();
                      setFieldValue("expireDate", formattedDate);
                    }}
                    minDate={values?.startDate ? dayjs(values.startDate) : null}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={
                      values?.expireTime
                        ? dayjs(values.expireTime, "HH:mm")
                        : null
                    }
                    onChange={(newValue) => {
                      const formattedTime = dayjs(newValue?.$d).format("HH:mm");
                      setFieldValue("expireTime", formattedTime);
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "7px",
                        borderRadius: "4px",
                        border: "none", // Remove the border from the input
                      },
                      "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                        color: "#673C8E", // Change the color of the icon button
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        border: "2px solid #673C8E",
                      },
                      "& .MuiOutlinedInput-root fieldset:hover": {
                        borderColor: "#673C8E", // Change border color on hover
                      },
                      "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                        borderColor: "#673C8E", // Change border color when focused
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* ---------------preview banner image */}
          <Box
            sx={{
              width: "100%",
              marginTop: "42px",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "18px",
              paddingRight: "24px",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "22px",
                color: "#673C8E",
              }}
            >
              Preview Banner Image
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "310px",
                overflow: "hidden", // Ensure the image fits within the box without stretching
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Optional: adds a shadow effect
              }}
            >
              <img
                src={
                  selectedFile ||
                  (offer
                    ? `${BASE_URL}regularOffers/${values.bannerImg}`
                    : defaultBannerImg)
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover", // Ensure the image covers the box without distortion
                }}
              />
            </Box>
          </Box>
          {/* ---------------preview banner image */}

          {/* -----------------Upload banner image--------------- */}
          <Box
            sx={{
              width: "100%",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingLeft: "18px",
              paddingRight: "24px",
            }}
            onClick={handleBoxClick}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "22px",
                color: "#673C8E",
              }}
            >
              Upload Banner Image<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "188px",
                background: "#F8F9FB",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                border: isDragging ? "2px dashed #673C8E" : "none", // Dashed border when dragging
              }}
              onClick={() => fileInputRef.current.click()} // Handle click to open file dialog
              onDragOver={(e) => {
                e.preventDefault(); // Prevent default behavior (open in new tab)
                setIsDragging(true); // Indicate dragging
              }}
              onDragLeave={() => setIsDragging(false)} // Remove dragging state on drag leave
              onDrop={handleFileDrop} // Handle drop event
            >
              <img src={upload} width="24" height="24" />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  fontSize: "19px",
                  color: "#673C8E",
                }}
              >
                Click to upload or Drag and drop here
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  fontSize: "19px",
                  color: "#000",
                }}
              >
                Max.File Size15MB
              </Typography>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Box>
          </Box>
          {/* ---------------Upload banner image------------------- */}

          {/* -------------offer valid for--------------- */}
          <Box
            sx={{
              width: "100%",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingLeft: "18px",
              paddingRight: "24px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "22px",
                color: "#673C8E",
              }}
            >
              Offer Valid For<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box
              sx={{
                padding: "22px",
                borderRadius: "4px",
                border: "1px solid #673C8E",
                boxShadow:
                  "0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07)",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="validFor"
                      value="Drivers"
                      checked={values.validFor.includes("Drivers")}
                      onChange={(event) =>
                        handleCheckboxChange(event, setFieldValue, values)
                      }
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#683C8C",
                      fontSize: "20px",
                    },
                  }}
                  label="Drivers"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="validFor"
                      value="Clients"
                      checked={values.validFor.includes("Clients")}
                      onChange={(event) =>
                        handleCheckboxChange(event, setFieldValue, values)
                      }
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#683C8C",
                      fontSize: "20px",
                    },
                  }}
                  label="Clients"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="validFor"
                      value="Website"
                      checked={values.validFor.includes("Website")}
                      onChange={(event) =>
                        handleCheckboxChange(event, setFieldValue, values)
                      }
                      sx={{
                        color: "#683C8C",
                        "&.Mui-checked": {
                          color: "#683C8C",
                        },
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#683C8C",
                      fontSize: "20px",
                    },
                  }}
                  label="Website"
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>

        {/* ----------------action buttons--------------------- */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            paddingRight: "24px",
            gap: 2,
            position: "absolute",
            right: 0,
            bottom: "30px",
          }}
        >
          {id && (
            <Button
              variant="outlined"
              size="small"
              sx={{
                padding: "10px 30px",
                textTransform: "capitalize",
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
                borderRadius: "4px",
                fontWeight: 600,
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "rgba(103, 60, 142, 1)",
                  borderColor: "rgba(103, 60, 142, 1)",
                },
              }}
              onClick={(e) => handleOpenRegularOfferModal(offer)}
            >
              {offer && offer.archived ? "Unarchived" : "Archive"}
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={handleBackClick}
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "transparent",
              color: "rgba(103, 60, 142, 1)",
              borderColor: "rgba(103, 60, 142, 1)",
              borderRadius: "4px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "transparent",
                color: "rgba(103, 60, 142, 1)",
                borderColor: "rgba(103, 60, 142, 1)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              padding: "10px 30px",
              textTransform: "capitalize",
              backgroundColor: "rgba(103, 60, 142, 1)",
              color: "#fff",
              borderRadius: "4px",
              fontWeight: 600,
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "rgba(103, 60, 142, 1)",
                color: "#fff",
              },
            }}
            // disabled={
            //   values.validFor.length == 0 ||
            //   values.startDate == "" ||
            //   values.bannerImg == ""
            //     ? true
            //     : false
            // }
            // type="submit"
            onClick={handleOfferSubmit}
          >
            {id ? "Update" : " Create Offer"}
          </Button>
        </Box>
        {/* ----------------action buttons--------------------- */}
      </Grid>
      {openRegularOfferModal && selectedRow && (
        <RegularOfferModal
          open={openRegularOfferModal}
          archived={selectedRow.archived}
          onSubmit={handleOfferArchiveSubmit}
          onClose={handleCloseModal}
        />
      )}
    </form>
  );
}
