import { Grid, Typography } from "@mui/material";
import React from "react";

export function Acceptable() {
  return (
    <Grid container sx={{ padding: "0 18px" }}>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "30px",
            color: "#673C8E", // Return the value directly
          }}
        >
          Acceptable
        </Typography>
      </Grid>
    </Grid>
  );
}
