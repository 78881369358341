import React from "react";
import {
  Box,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  useTheme,
  List,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { NightsStayOutlined } from "@mui/icons-material";
import { icons } from "../icons";
import { ThemeSwitcher } from "../theme/ThemeSwitcher";
import { useThemeContext } from "../theme/ThemeContextProvider";

export function Sidebar() {
  const { mode, toggleColorMode } = useThemeContext();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const theme = useTheme();

  const navLinks = [
    {
      label: "Home",
      route: "/dashboard",
      icon: <img style={{ width: "20px" }} src={icons.home} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.homeWhite} alt="Logo" />
      ),
    },
    {
      label: "Orders",
      route: "/services/orders",
      icon: <img style={{ width: "20px" }} src={icons.order} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.orderWhite} alt="Logo" />
      ),
    },
    {
      label: "Reports",
      route: "/services/reports",
      icon: <img style={{ width: "20px" }} src={icons.report} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.reportWhite} alt="Logo" />
      ),
    },
    {
      label: "Clients",
      route: "/services/clients",
      icon: <img style={{ width: "20px" }} src={icons.client} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.clientWhite} alt="Logo" />
      ),
    },
    {
      label: "Drivers",
      route: "/services/drivers",
      icon: <img style={{ width: "20px" }} src={icons.driver} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.driverWhite} alt="Logo" />
      ),
    },
    {
      label: "Calendar",
      route: "/services/calendar",
      icon: <img style={{ width: "20px" }} src={icons.calendar} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.calendarWhite} alt="Logo" />
      ),
    },
    {
      label: "Chats",
      route: "/services/chats",
      icon: <img style={{ width: "20px" }} src={icons.chat} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.chatWhite} alt="Logo" />
      ),
    },
    {
      label: "Offers",
      route: "/services/regular-offers",
      route2: "/services/help-offers",
      icon: <img style={{ width: "20px" }} src={icons.offer} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.offerWhite} alt="Logo" />
      ),
    },
    {
      label: "Tutorials",
      route: "/services/tutorials",
      icon: <img style={{ width: "20px" }} src={icons.tutorial} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.tutorialWhite} alt="Logo" />
      ),
    },
    {
      label: "Reviews",
      route: "/services/reviews",
      icon: <img style={{ width: "20px" }} src={icons.review} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.reviewWhite} alt="Logo" />
      ),
    },
  ];

  const accountSettings = [
    {
      label: "Account Settings",
      route: "/others/account-settings",
      icon: <img style={{ width: "20px" }} src={icons.account} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.accountWhite} alt="Logo" />
      ),
    },
    {
      label: "Statements",
      route: "/others/statements",
      icon: <img style={{ width: "20px" }} src={icons.statement} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.statementWhite} alt="Logo" />
      ),
    },
    {
      label: "Help Center",
      route: "/others/help-center",
      icon: <img style={{ width: "20px" }} src={icons.help} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.helpWhite} alt="Logo" />
      ),
    },
    {
      label: "Delete Account",
      route: "/others/delete-account",
      icon: (
        <img style={{ width: "20px" }} src={icons.deleteAccount} alt="Logo" />
      ),
      iconWhite: (
        <img
          style={{ width: "20px" }}
          src={icons.deleteAccountWhite}
          alt="Logo"
        />
      ),
    },
    {
      label: "Logout",
      route: "/others/logout",
      icon: <img style={{ width: "20px" }} src={icons.logoutIcon} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.logoutWhite} alt="Logo" />
      ),
    },
  ];

  const legals = [
    // {
    //   label: "Privacy policy",
    //   route: "/legals/privacy-policy",
    //   icon: <img style={{ width: "20px" }} src={icons.privacy} alt="Logo" />,
    //   iconWhite: (
    //     <img style={{ width: "20px" }} src={icons.accountWhite} alt="Logo" />
    //   ),
    // },
    {
      label: "Terms of use",
      route: "/legals/terms-of-use",
      icon: <img style={{ width: "20px" }} src={icons.terms} alt="Logo" />,
      iconWhite: (
        <img style={{ width: "20px" }} src={icons.statementWhite} alt="Logo" />
      ),
    },
    // {
    //   label: "Promo terms",
    //   route: "/legals/promo-terms",
    //   icon: <img style={{ width: "20px" }} src={icons.promo} alt="Logo" />,
    //   iconWhite: (
    //     <img style={{ width: "20px" }} src={icons.helpWhite} alt="Logo" />
    //   ),
    // },
    // {
    //   label: "Refund and cancellation",
    //   route: "/legals/refund-cancel",
    //   icon: <img style={{ width: "20px" }} src={icons.chat} alt="Logo" />,
    //   iconWhite: (
    //     <img style={{ width: "20px" }} src={icons.chatWhite} alt="Logo" />
    //   ),
    // },
    // {
    //   label: "Acceptable items",
    //   route: "/legals/acceptable-item",
    //   icon: <img style={{ width: "20px" }} src={icons.acceptable} alt="Logo" />,
    //   iconWhite: (
    //     <img style={{ width: "20px" }} src={icons.logoutWhite} alt="Logo" />
    //   ),
    // },
    // {
    //   label: "Out of area policy",
    //   route: "/legals/out-area-policy",
    //   icon: <img style={{ width: "20px" }} src={icons.outArea} alt="Logo" />,
    //   iconWhite: (
    //     <img style={{ width: "20px" }} src={icons.logoutWhite} alt="Logo" />
    //   ),
    // },
  ];

  return (
    <Drawer
      className="custom-scrollbar"
      variant="permanent"
      sx={{
        borderRight: "1px solid #673C8E",
        boxShadow: "0px 4px 4px 0px #673C8E",
        width: 300,

        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 300,
          boxSizing: "border-box",
          background: theme.palette.primary.main,
        },
      }}
    >
      <Grid sx={{ textAlign: "center" }}>
        <img
          style={{ width: mode === "dark" ? "100px" : "130px" }}
          src={mode === "dark" ? icons.logoPurple : icons.logo}
          alt="Logo"
        />
      </Grid>
      <Box sx={{ padding: "0 36px", marginTop: "6px" }}>
        <Typography
          sx={{ color: "#673C8E", fontSize: "13px", fontWeight: 600 }}
        >
          SERVICES
        </Typography>
        <List>
          {navLinks.map((link) => {
            const isActive =
              location.pathname.includes(link.route) ||
              (link.route2 && location.pathname.includes(link.route2)) ||
              (link.route === "/orders" &&
                location.pathname.includes("/order-detail")) ||
              (link.route === "/clients" &&
                location.pathname.includes("/client-detail")) ||
              (link.route === "/drivers" &&
                location.pathname.includes("/driver-detail"));
            return (
              <ListItem
                key={link.label}
                disablePadding
                sx={{
                  background: isActive
                    ? "#673C8E"
                    : theme.palette.background.default,
                  marginBottom: "2px",
                  borderRadius: "4px",
                  color: isActive ? "#FFFFFF" : "inherit",
                }}
              >
                <ListItemButton
                  sx={{ padding: "2px 10px" }}
                  onClick={() => {
                    navigate(link.route);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      fontSize: "18px",
                      minWidth: "34px",
                      color: isActive ? "#FFFFFF" : "rgba(104, 60, 140, 1)",
                    }}
                  >
                    {isActive ? link.iconWhite : link.icon}
                    {/* {isActive
                      ? link.iconWhite
                      : mode == "dark"
                      ? link.iconWhite
                      : link.icon} */}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: isActive ? "#FFFFFF" : theme.palette.text.primary,
                    }}
                    primary={link.label}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Box sx={{ padding: "0 36px", marginTop: "6px" }}>
        <Typography
          sx={{ color: "#673C8E", fontSize: "13px", fontWeight: 600 }}
        >
          LEGAL CONTENT MANAGEMENT
        </Typography>
        <List>
          {legals.map((link) => {
            const isActive = location.pathname.includes(link.route);
            return (
              <ListItem
                key={link.label}
                disablePadding
                sx={{
                  background: isActive
                    ? "#673C8E"
                    : theme.palette.background.default,
                  marginBottom: "2px",
                  borderRadius: "4px",
                  color: isActive ? "#FFFFFF" : "inherit",
                }}
              >
                <ListItemButton
                  sx={{ padding: "2px 10px" }}
                  onClick={() => {
                    navigate(link.route);
                    // if (link.label === "Logout") {
                    //   logout();
                    // } else {
                    //   navigate(link.route);
                    // }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      fontSize: "18px",
                      minWidth: "34px",
                      color: isActive ? "#FFFFFF" : "inherit",
                    }}
                  >
                    {isActive ? link.iconWhite : link.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: isActive ? "#FFFFFF" : "inherit",
                    }}
                    primary={link.label}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box sx={{ padding: "0 36px", marginTop: "6px" }}>
        <Typography
          sx={{ color: "#673C8E", fontSize: "13px", fontWeight: 600 }}
        >
          OTHERS
        </Typography>
        <List>
          {accountSettings.map((link) => {
            const isActive = location.pathname.includes(link.route);
            return (
              <ListItem
                key={link.label}
                disablePadding
                sx={{
                  background: isActive
                    ? "#673C8E"
                    : theme.palette.background.default,
                  marginBottom: "2px",
                  borderRadius: "4px",
                  color: isActive ? "#FFFFFF" : "inherit",
                }}
              >
                <ListItemButton
                  sx={{ padding: "2px 10px" }}
                  onClick={() => {
                    navigate(link.route);
                    // if (link.label === "Logout") {
                    //   logout();
                    // } else {
                    //   navigate(link.route);
                    // }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      fontSize: "18px",
                      minWidth: "34px",
                      color: isActive ? "#FFFFFF" : "inherit",
                    }}
                  >
                    {isActive ? link.iconWhite : link.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: isActive ? "#FFFFFF" : "inherit",
                    }}
                    primary={link.label}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <Box sx={{ padding: "0 36px", marginTop: "6px", display: "none" }}>
        <Typography
          sx={{
            color: "#673C8E",
            // color: mode === "dark" ? "#ffffff" : "#673C8E",
            fontSize: "13px",
            fontWeight: 600,
          }}
        >
          MODE
        </Typography>
        <List
          sx={{ paddingTop: "0 !important", paddingBottom: "0 !important" }}
        >
          <ListItem
            key="mode"
            disablePadding
            sx={{
              // background: "rgba(104, 60, 140, 0.06)",
              // marginBottom: "2px",
              borderRadius: "4px",
              color: "inherit",
            }}
          >
            <ListItemButton sx={{ padding: "0px 10px" }}>
              <ListItemIcon
                sx={{
                  fontSize: "18px",
                  minWidth: "34px",
                  color: "#673C8E",
                }}
              >
                {React.cloneElement(<NightsStayOutlined />, {
                  fontSize: "inherit",
                })}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "inherit",
                }}
                primary={mode === "dark" ? "Light Mode" : "Dark Mode"}
              />
              <ThemeSwitcher />
            </ListItemButton>
            {/* <IOSSwitch sx={{ m: 1 }} defaultChecked /> */}
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
